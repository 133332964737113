'use client'

import Link from 'next/link'
import React, { ComponentPropsWithoutRef, ReactNode, useCallback } from 'react'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'
import { AnalyticsEventData } from '@/analytics/models'

type Props = ComponentPropsWithoutRef<'a'> & {
  kind?: 'button' | 'link'
  href: string
  event: keyof AnalyticsEventData
  data: AnalyticsEventData[keyof AnalyticsEventData]
  children: ReactNode
}

export const LinkWithAnalytics = ({ kind = 'button', href, event, data, children, ...props }: Props) => {
  const handleClick = useCallback(() => {
    trackAnalyticsEvent({ event, data })
  }, [event, data])

  return (
    <Link
      className={
        kind === 'button' ? 'btn btn-primary btn-wide select-auto' : 'link-hover link link-primary font-semibold'
      }
      href={href}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Link>
  )
}
