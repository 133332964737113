'use client'

import React, { useCallback, useState } from 'react'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'

// Taken from packages/website/tailwind.config.ts
const THEMES = [
  'sub',
  'light',
  'dark',
  'cupcake',
  'bumblebee',
  'emerald',
  'corporate',
  'synthwave',
  'retro',
  'cyberpunk',
  'valentine',
  'halloween',
  'garden',
  'forest',
  'aqua',
  // 'lofi', // removed low contrast themes
  'pastel',
  'fantasy',
  'wireframe',
  // 'black', // as above
  // 'luxury',// as above
  'dracula',
  'cmyk',
  'autumn',
  'business',
  'acid',
  'lemonade',
  'night',
  'coffee',
  'winter',
  'dim',
  'nord',
  'sunset',
]

export const ThemingTerminal = () => {
  const [theme, setTheme] = useState(THEMES[0])

  const onCycleTheme = useCallback(() => {
    // select the next theme
    const currentIndex = THEMES.indexOf(theme)
    const nextIndex = (currentIndex + 1) % THEMES.length
    const nextTheme = THEMES[nextIndex]

    setTheme(nextTheme)

    trackAnalyticsEvent({
      event: 'theme_changed',
      data: { theme: nextTheme },
    })
  }, [theme])

  return (
    <div data-theme={theme} className="mockup-code w-full max-w-lg">
      <pre
        data-prefix="$"
        className="cursor-pointer select-none text-accent transition-opacity hover:opacity-60"
        onClick={onCycleTheme}
      >
        <code>./cycle-camouflage.sh</code>
      </pre>

      <pre data-prefix=">" className="text-secondary">
        <code>⚠️ Activated via click!</code>
      </pre>

      <pre data-prefix=">">
        <code>[{theme}] active</code>
      </pre>
    </div>
  )
}
