'use client'

import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  features: Array<{
    id: string
    label: string
  }>
}

export const FeatureNavbar = ({ features }: Props) => {
  const [activeFeature, setActiveFeature] = useState<(typeof features)[0] | null>(features[0])
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const active = features.find(feature => `#${feature.id}` === `#${entry.target.id}`)
          if (active) setActiveFeature(active)
        }
      })
    }

    observer.current = new IntersectionObserver(handleIntersection, {
      root: null, // observes relative to the viewport
      threshold: 0.3, // triggers when 30% of the section is visible
    })

    // Observe all feature sections
    features.forEach(feature => {
      const element = document.getElementById(feature.id)
      if (element) observer.current?.observe(element)
    })

    // Clean up observer on component unmount
    return () => {
      observer.current?.disconnect()
    }
  }, [features])

  return (
    <div className="sticky inset-x-0 top-0 z-10 flex w-full items-center justify-center gap-x-8 rounded-lg bg-base-100 p-4">
      {features.map(feature => (
        <Link
          key={feature.id}
          href={`#${feature.id}`}
          aria-label={`Navigate to feature: ${feature.label}`}
          className={`btn btn-circle btn-neutral btn-xs z-10 ${
            activeFeature?.id === feature.id
              ? 'bg-secondary hover:bg-secondary focus:bg-secondary focus-visible:bg-secondary active:bg-secondary'
              : 'bg-neutral'
          }`}
        >
          {activeFeature?.id === feature.id && <div className="h-5 w-5 animate-ping rounded-full bg-secondary" />}
        </Link>
      ))}
    </div>
  )
}
